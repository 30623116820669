.container{
    /* width: 200px;
    height: 200px; */
    position: relative;
    /* margin: 20px; */
}
.padding {
    padding-top: 0px !important;
    padding-bottom: 0.5rem !important;
}
.info {
    display:inline;
    position: relative;
    /* float:right; */
    margin: 0;
    letter-spacing: 0.02em;
    color : #4575F2;
    font-size: 14px;
    transition: 3s;
}
.popUp {
    position: relative;
    text-align: right;
    letter-spacing: 0.02em;
    color : #4575F2;
    font-size: 14px;
    transition: 3s;
    
}
.info:hover {
    cursor: pointer;
}
.popUp:hover {
    cursor: pointer;
}

.popUp > img {
    width: 13px;
    height: 13px;
}

.box {
    /* vertical-align: top; */
    width: 390px!important;
    font-size: 12px;
    color: #808188;
    font-weight: 500;
    letter-spacing: 0.02em;    
    text-align: left;
    border: 1px solid #dadbe0;
    box-shadow: 0px 2px 8px 0px #00000014;
    margin: auto;
    width: 50%;
    /* height: 130px!important; */
    /* position: relative; */
    /* margin-left: 0px; */
    /* align-content: flex-end; */
    /* position: absolute; */
    
}
.box:hover{
    cursor: auto;
}
.box > p {
    font-size: 14px;
    margin-left: 20px;
    padding-top: 20px;
    color: #000;
    margin-bottom: 12px;
    display: inline;
}
.box > img {
    float: right;
    padding-right: 17px;
    padding-top: 5px;
}

.box > img:hover {
    cursor: pointer;
}

.box > ul {
    padding-left: 36px;
    font-weight: 400;
}
.stack-top{
    /* z-index: 9; */
    position: absolute;
    /* top: 40px; */
    bottom: 40px;
    right: 0px;
    /* margin: 20px; */
}

.boxText {
    /* vertical-align: top; */
    width: 390px!important;
    font-size: 12px;
    color: #808188;
    /* font-weight: 500; */
    letter-spacing: 0.02em;    
    text-align: center;
    border: 1px solid #dadbe0;
    box-shadow: 0px 2px 8px 0px #00000014;
    margin: auto;
    width: 50%;
    /* height: 130px!important; */
    /* position: relative; */
    /* margin-left: 0px; */
    /* align-content: flex-end; */
    /* position: absolute; */
    
}
.boxText > img:hover {
    cursor: pointer;
}
.boxText > img {
    float: right;
    padding-right: 8px;
    padding-top: 8px;
}

.boxText:hover{
    cursor: auto;
}
.boxText > p {
    /* font-size: 14px; */
    margin: 2px 15px;
    padding-top: 20px;
    color: #000;
    /* margin-bottom: 12px; */
    /* display: inline; */
}