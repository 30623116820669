.test {
    border: 4px solid #FFC700;
    border-radius: 8px;
}
.mid {
    /* text-align: center; */
    overflow: auto;
    position: relative;
    
}
.recommendation {
    position: absolute;    
    /* width: 100%; */
    text-align: center;
    margin: 0;
    width: 174px;
    height: 36px;
    left: 50%;
    /* left: 425px; */
    top: 0px;
    background: #FFC700;
    transform: translate(-50%);
}
.button {
    padding: 0;
    border: none;
    outline: none;
    border-radius: 0px 0px 8px 8px;
}