.wrapper {
  padding: 150px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.carousel {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.carousel__item {
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  opacity: 0;
  will-change: transform, opacity;
  animation: carousel-animate-vertical 27s ease-in-out infinite;
}

.carousel__item:nth-child(1) {
  animation-delay: calc(3s * -1);
}

.carousel__item:nth-child(2) {
  animation-delay: calc(3s * 0);
}

.carousel__item:nth-child(3) {
  animation-delay: calc(3s * 1);
}

.carousel__item:nth-child(4) {
  animation-delay: calc(3s * 2);
}

.carousel__item:nth-child(5) {
  animation-delay: calc(3s * 3);
}

.carousel__item:nth-child(6) {
  animation-delay: calc(3s * 4);
}

.carousel__item:nth-child(7) {
  animation-delay: calc(3s * 5);
}

.carousel__item:nth-child(8) {
  animation-delay: calc(3s * 6);
}

.carousel__item:last-child {
  animation-delay: calc(-3s * 2);
}

.carousel__item-body {
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  /* padding: 16px 20px 16px 70px; */
  padding: 16px;
}

@keyframes carousel-animate-vertical {
  0% {
    transform: translateY(75%) scale(0.6);
    opacity: 0;
    visibility: hidden;
  }
  1.5%,
  11.1111111111% {
    transform: translateY(75%) scale(0.85);
    opacity: 0.4;
    z-index: 1;
    visibility: visible;
  }
  12.6111111111%,
  22.2222222222% {
    transform: translateY(0) scale(1);
    opacity: 1;
    z-index: 1000;
    visibility: visible;
  }
  23.7222222222%,
  33.3333333333% {
    transform: translateY(-75%) scale(0.85);
    opacity: 0.4;
    z-index: 1;
    visibility: visible;
  }
  34.8333333333% {
    transform: translateY(-75%) scale(0.6);
    opacity: 0;
    visibility: visible;
  }
  100% {
    transform: translateY(-75%) scale(0.6);
    opacity: 0;
    visibility: hidden;
  }
}

.carousel__card__content .credit-company-logo {
  max-width: 100%;
}

.carousel__card {
  padding: 20px 20px;
}

@media only screen and (max-width: 900px) {
  .carousel__card {
    padding: 10px;
  }

  .carousel__card__content h2 {
    font-size: 16px;
    vertical-align: 3px;
  }

  .carousel__card__content .offer-status {
    font-size: 11px;
  }

  .carousel__card__content .credit-company-logo {
    height: 25px;
  }
}

@media only screen and (max-width: 800px) {
  .carousel__card__content h2 {
    font-size: 14px;
  }

  .carousel__card__content .offer-status {
    font-size: 10px;
  }

  .carousel__card__content .credit-company-logo {
    height: 20px;
  }
}

@media only screen and (max-width: 550px) {
  .carousel__card__content h2 {
    font-size: 11px;
  }

  .carousel__card__content .offer-status {
    font-size: 9px;
    padding: 4px 8px;
  }

  .carousel__card__content .credit-company-logo {
    height: 16px;
  }
}

@media only screen and (max-width: 440px) {
  .wrapper {
    padding: 100px 0px;
  }

  .carousel__card {
    padding: 5px 10px;
  }

  .carousel__card__content h2 {
    font-size: 9px;
    line-height: 20px;
  }

  .carousel__card__content .offer-status {
    font-size: 8px;
    /* padding: 5px 8px; */
  }
}
